import appConfig from 'configs/app.config';
import {ApiService, PlatFormService} from 'services/ApiService';

const baseEndPoint = '/product_tagging';

const fetchAllProducts = async (filters, env = 'prod') => {
  // Filter out empty, undefined and null values
  const skimmedFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, v]) => v != null && v !== '')
  );

  const {
    data: { hits: products, aggregations },
  } = await ApiService.fetchData({
    // url: `https://mmh2kxmao4.execute-api.ap-south-1.amazonaws.com/prod/v4/search`,
    url: `${appConfig.apiPrefix}-search/v4/search`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { is_raw: true, ...skimmedFilters },
  });

  const mutatedData = {
    aggregations,
    products: products.hits.map((product) => product._source),
    total: products.total.value,
  };

  return mutatedData;
};

const fetchPlatformProducts = async (filters = {}) => {
  const response = await PlatFormService.fetchData({
    params: {
      route: 'api/product/search',
      ...filters,
    },
  });
  return response.data;
};

const fetchCustomTags = async (env = 'prod') => {
  const response = await ApiService.fetchData({
    url: `${baseEndPoint}/${env}/custom_tags`,
    method: 'get',
  });
  return response.data;
};

const fetchSources = async () => {
  const response = await PlatFormService.fetchData({
    params: {
      route: 'api/analytics_brand/souceList',
      status: 1,
    },
  });
  return response.data;
};
const getMinPrice = async (product_id, variant_id, publisher_id) => {
  const response = await PlatFormService.fetchData({
    params: {
      route: 'api/product/getMinPrice',
      product_id,
      variant_id,
      publisher_id,
    }
  });
  return response.data;
};
const fetchVendors = async (sourceId) => {
  if (!sourceId) return;
  const response = await PlatFormService.fetchData({
    params: {
      route: 'api/analytics_brand/sourceVendor',
      sourceId: sourceId,
    },
  });
  return response.data;
};
const fetchPublishers = async () => {
  const response = await PlatFormService.fetchData({
    params: {
      route: 'api/publisher/list',
    },
  });
  return response.data;
};

const updateCustomTags = async () => {
  const response = await ApiService.fetchData({
    url: `${baseEndPoint}/custom_tags`,
    method: 'post',
  });

  return response;
};

const removeTag = async (product_id, tag, env = 'prod') => {
  const response = await ApiService.fetchData({
    url: `${baseEndPoint}/${env}/remove_custom_tags_from_products`,
    method: 'post',
    data: { product_ids: [`${product_id}`], custom_tags: [`${tag}`] },
  });

  return response;
};

const addTag = async (tag, env = 'prod') => {
  const response = await ApiService.fetchData({
    url: `${baseEndPoint}/${env}/custom_tags/new`,
    method: 'post',
    data: { name: `${tag}` },
  });

  return response;
};

const AssignTag = async ({ product_ids, custom_tags }, env = 'prod') => {
  const response = await ApiService.fetchData({
    url: `${baseEndPoint}/${env}/add_custom_tags_to_products`,
    method: 'post',
    data: {
      product_ids,
      custom_tags,
    },
  });

  return response;
};

const ProductTaggingService = {
  fetchSources,
  fetchVendors,
  fetchPublishers,
  fetchAllProducts,
  fetchPlatformProducts,
  fetchCustomTags,
  updateCustomTags,
  removeTag,
  addTag,
  getMinPrice,
  AssignTag,
};

export default ProductTaggingService;
